import React from "react";
import { Link } from "react-router-dom";

class NotFound extends React.Component {
    render() {
        const pageStyle = {
            backgroundColor: "#f7f7f7",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Arial, sans-serif"
        };

        const containerStyle = {
            textAlign: "center"
        };

        const errorTitleStyle = {
            fontSize: "120px",
            fontWeight: "bold",
            color: "#ff6b6b",
            margin: "0"
        };

        const h2Style = {
            fontSize: "24px",
            color: "#555",
            marginTop: "10px"
        };

        const linkStyle = {
            display: "inline-block",
            marginTop: "30px",
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "white",
            textDecoration: "none",
            borderRadius: "5px",
            fontWeight: "bold"
        };

        const linkHoverStyle = {
            backgroundColor: "#0056b3",
            color: "#fff"
        };

        return (
            <>
                <div style={pageStyle}>
                    <div style={containerStyle}>
                        <h1 style={errorTitleStyle}>404</h1>
                        <h2 style={h2Style}>Oops! Page not found</h2>
                    </div>
                </div>
            </>
        );
    }
}

export default NotFound;
